import VanillaCalendar from 'vanilla-calendar-pro';
import 'vanilla-calendar-pro/build/vanilla-calendar.min.css';
import { mergeDeep } from '../utils';

const vanillaCalendarInit = () => {
  const { getData } = window.phoenix.utils;
  document.querySelectorAll('.calendar').forEach(item => {
    const userOptions = getData(item, 'options');
    const dateInput = getData(item, 'input');
    const options = {
      type: 'default',
      date: {
        today: new Date()
      },
      settings: {
        lang: 'es-ES',
        visibility: {
          themeDetect: 'html[data-bs-theme]'
        }
      },
      actions: {
        clickDay(event, self) {
          const [selectedDate] = self.selectedDates;
          document.querySelector(dateInput).value = selectedDate;
        }
        // changeToInput(e, calendar, self) {
        //   const input = self;
        //   if (!input.HTMLInputElement) return;
        //   if (input.selectedDates[0]) {
        //     const [selectedDate] = input.selectedDates;
        //     input.HTMLInputElement.value = selectedDate;
        //     // if you want to hide the calendar after picking a date
        //     calendar.hide();
        //   } else {
        //     input.HTMLInputElement.value = '';
        //   }
        // }
      }
      // ...userOptions
    };
    const settings = mergeDeep(options, userOptions);
    const calendar = new VanillaCalendar(item, settings);
    calendar.init();
  });
};

export default vanillaCalendarInit;
